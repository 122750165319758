<template>
  <div>
    <v-card class='mx-3 pt-1 transparent' elevation=0 style='max-width: 1000px;'>
      <v-container>

        <v-card>
          <v-card-text>
            <v-list>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class=''>Sign-in PIN</v-list-item-title>
                  <v-list-item-subtitle>Change your sign-in PIN.</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn class='primary' @click='changePIN'><v-icon class='mr-2'>mdi-lock</v-icon>Set PIN</v-btn>
                </v-list-item-action>
              </v-list-item>

              <!--<v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class=''>Taskflow Presets</v-list-item-title>
                  <v-list-item-subtitle>Create taskflows that can be quick-selected in job cards.</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn class='primary' @click='()=>{ $refs.taskflow_list.open() }'><v-icon class='mr-2'>mdi-view-list</v-icon>Taskflows</v-btn>
                </v-list-item-action>
              </v-list-item>-->

            </v-list>
          </v-card-text>
        </v-card>

      </v-container>
    </v-card>

  </div>
</template>

<style lang="scss">
</style>


<script>
import { mapActions, mapGetters } from 'vuex';
//import ListModal from '@/components/Common/ListModal'

export default {
  name: 'Authentication',
  components: {
  },
  computed: {
    ...mapGetters("AUTH", ["BROWSER_ID"]),
  },
  watch:{
    $route: { handler(){ let t = `Authentication`; this.SET_TITLE(t); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ['SET_TITLE', 'CONFIRM', 'PIN', 'ALERT']),
    ...mapActions("API", ['API']),

    async changePIN(){
      let pin = await this.PIN({ title: 'Change PIN', message: 'Enter your new PIN:' })
      this.API({ method: 'POST', endpoint: `auth/update_pin`, data: { pin, browser: this.BROWSER_ID } })
      .then(() => {
        this.ALERT({ title: "PIN Changed", message: "Your sign-in PIN has been updated." })
      })
    }

  },
  mounted(){
  }
}
</script>
